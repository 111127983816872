/* Global
----------------------------------------------- */
html {
    font-size: 10px;
    font-family: sans-serif;
}
* {
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -o-box-sizing:border-box;
    box-sizing:border-box;
}
:before,
:after {
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box
}
a,
input,
textarea,
button,
select {
    outline: none;
}
h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}
h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}
a:hover,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
    text-decoration: none;
}
a:focus,
input:focus,
textarea:focus,
button:focus,
select:focus {
    outline: none !important;
    text-decoration: none;
}
hr{
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
    height: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
body {
    position: relative;
    font-family: sans-serif;
    font-size: 15px;
    color: #7b7b7b;
    background-color: #fff;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    letter-spacing: 0.014em;
    line-height: 1.42857;
}
input, textarea {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
}
input[type="search"],
input[type="text"],
input[type="url"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="file"] {
    background: none;
    border: 0;
    background-color: #f7f8f8;
    height: 42px;
    line-height: 42px;
    padding: 0 15px;
    color: #888888;
    font-family: "Lato", sans-serif !important;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
select {
    border: 1px solid #eee;
    height: 42px;
    line-height: 42px;
    padding: 0 10px;
    color: #888888;
    font-family: "Lato", sans-serif;
    font-size: 14px;
}
textarea {
    background: none;
    border: 0;
    background-color: #f7f8f8;
    resize: none;
    padding: 15px;
    height: 100px;
    color: #888888;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    vertical-align: middle;
}
input::-webkit-input-placeholder {
    color: #888888;
}
input:-moz-placeholder {
    color: #888888;
}
input::-moz-placeholder {
    color: #888888;
}
input:-ms-input-placeholder {
    color: #888888;
}
textarea::-webkit-input-placeholder {
    color: #888888;
}
textarea:-moz-placeholder {
    color: #888888;
}
textarea::-moz-placeholder {
    color: #888888;
}
textarea:-ms-input-placeholder {
    color: #888888;
}
a {
    color: #e15d5d;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.02em;
}
a:hover {
    color: #000;
}
code, kbd, pre, samp {
    font-family: "Raleway", sans-serif;
    font-size: inherit;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    letter-spacing: 0.015em;
}
code {
    background-color: #eee;
    letter-spacing: 0.015em;
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: 0;
}
p {
    line-height: 1.8em;
    letter-spacing: 0.02em;
}
ol li, ul li {
    letter-spacing: 0.02em;
}
::-moz-selection {
    color: #fff;
    background-color: #e15d5d;
}
::selection {
    color: #fff;
    background-color: #e15d5d;
}
#page-wrap {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    z-index: 9;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #FFF url("http://4.bp.blogspot.com/-u8ZOp2KyBdo/VWcsFN545bI/AAAAAAAAFbY/fptXy6fX66k/s1600/header-top.png") repeat-x top center;
}
.container {
    width: 1170px;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.tb {
    display: table;
    width: 100%;
}
.tb-cell {
    display: table-cell;
    vertical-align: middle;
}
.h1, h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: #484848;
    line-height: 1.4em;
    letter-spacing: 0.01em;
}
.h1 a, h1 a {
    color: inherit;
}
.h2, h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #484848;
    line-height: 1.4em;
    letter-spacing: 0.02em;
}
.h2 a, h2 a {
    color: inherit;
}
.h3, h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #484848;
    line-height: 1.5em;
    letter-spacing: 0.01em;
}
.h3 a, h3 a {
    color: inherit;
}
.h4, h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #484848;
    line-height: 1.5em;
    letter-spacing: 0.01em;
}
.h4 a, h4 a {
    color: inherit;
}
.h5, h5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #484848;
    line-height: 1.5em;
    letter-spacing: 0.01em;
}
.h5 a, h5 a {
    color: inherit;
}
.h6, h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #484848;
    line-height: 1.5em;
    letter-spacing: 0.01em;
}
.h6 a, h6 a {
    color: inherit;
}
.pi-btn {
    display: inline-block;
    background: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 400;
    color: #535353;
    border: 4px double #D8D8D8;
    text-align: center;
    text-transform: uppercase;
    padding: 13px 22px;
}
.pi-btn:hover {
    border-color: #e15d5d;
    color: #e15d5d;
}
.fl {
    float: left;
}
.fr {
    float: right;
}
blockquote, .blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
}
blockquote, .blockquote {
    border: 0;
    font-size: 14px;
    font-style: italic;
    padding-left: 30px;
    margin-top: 15px;
    margin-bottom: 20px;
    border-left: 3px solid #e15d5d;
}
blockquote cite, .blockquote cite {
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-style: normal;
    margin-top: 5px;
    text-transform: uppercase;
    text-decoration: underline;
}
abbr {
    color: #A1D71A;
    border-width: 2px;
}
mark, .mark {
    background-color: #e15d5d;
}
.icon {
    display: inline-block;
    font-style: normal;
}
.autoHeight {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.text-center {
    text-align: center;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}
.section, .widget {
    margin: 0;
    padding: 0;
}
.col-md-2, .col-md-3, .col-md-4, .col-md-6, .col-md-9 {
    float:left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col-md-9 {
    width:67%;
}
.col-md-6 {
    width:50%;
}
.col-md-4 {
    width:33.33333333%;
}
.col-md-3 {
    width:33%;
}
.col-md-2 {
    width:16.66666667%;
}
.clearfix::after, .dl-horizontal dd::after, .container::after, .container-fluid::after, .row::after, .form-horizontal .form-group::after, .btn-toolbar::after, .btn-group-vertical > .btn-group::after, .nav::after, .navbar::after, .navbar-header::after, .navbar-collapse::after, .pager::after, .panel-body::after, .modal-footer::after {
    clear: both;
}
.clearfix::before, .clearfix::after, .dl-horizontal dd::before, .dl-horizontal dd::after, .container::before, .container::after, .container-fluid::before, .container-fluid::after, .row::before, .row::after, .form-horizontal .form-group::before, .form-horizontal .form-group::after, .btn-toolbar::before, .btn-toolbar::after, .btn-group-vertical > .btn-group::before, .btn-group-vertical > .btn-group::after, .nav::before, .nav::after, .navbar::before, .navbar::after, .navbar-header::before, .navbar-header::after, .navbar-collapse::before, .navbar-collapse::after, .pager::before, .pager::after, .panel-body::before, .panel-body::after, .modal-footer::before, .modal-footer::after {
    display: table;
    content: " ";
}

/* Preloader
----------------------------------------------- */
#preloader {
    position: fixed;
    display: table;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 999999999;
}
#preloader .page-loading .item-icon {
    width: 40px;
    height: 40px;
    margin: auto;
    opacity: .9;
    border-right: 3px solid #e15d5d;
    border-bottom: 3px solid transparent;
    border-radius: 100%;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    -o-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
    }
}
@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    50% {
        -moz-transform: rotate(180deg);
    }
    100% {
        -moz-transform: rotate(359deg);
    }
}
@-o-keyframes spin {
    0% {
        -o-transform: rotate(0deg);
    }
    50% {
        -o-transform: rotate(180deg);
    }
    100% {
        -o-transform: rotate(359deg);
    }
}

/* Header
----------------------------------------------- */
.needo {
    height: 58px;
}
.header {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.header .logo {
    padding: 35px 20px;
}
.header .logo a {
    display: inline-block !important;
    margin: 0 auto;
}
.header .logo img {

}
.header.header-responsive .logo {
    margin-bottom: 0;
    margin-top: 52px;
}
.header.header-responsive .pi-navigation {
    position: fixed;
    top: 0;
    bottom: auto;
    border-top: 0;
}
div#topnav h2 {
    display: none;
}

.pi-navigation .navlist{text-align:center}
.pi-navigation {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 9999;
    height: 45px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.pi-navigation.nav-fixed {
    position: fixed;
    top: 0;
    bottom: auto;
}
.pi-navigation .container {
    height: 100%;
}
.pi-navigation1{
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;

    background-color: #444;
    z-index: 9999;
    height: 40px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}
.pi-navigation1.nav-fixed {
    position: fixed;
    top: 0;
    bottom: auto;
}
.pi-navigation1 .container {
    height: 100%;
}
.pi-navigation1 .search-box {
    position: absolute;
    top: 50%;
    right: -12px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.pi-navigation1 .search-box input[type="search"] {
    position: absolute;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    border: 1px solid #eee;
    top: 26px;
    right: 25px;
    width: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.pi-navigation1 .search-box input[type="search"].fadein {
    width: 200px;
    opacity: 1;
    visibility: visible;
}

.pi-navigation .navlist li a {
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    color: #333;
    text-transform: uppercase;
    line-height: 45px;
}
.pi-navigation1 .search-box .icon-search {
    display: block;
    position: absolute;
    width: 40px;
    height: 20px;
    text-align: center;
    cursor: pointer;
    color: #aaa;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.pi-navigation1 .search-box .icon-search .fa {
    line-height: 18px;
}
.pi-navigation1 .search-box .icon-search.active, .pi-navigation .search-box .icon-search:hover {
    color: #e15d5d;
}
.pi-navigation1 .search-box .icon-search:before {
    content: '';
    display: block;
    position: absolute;
    border-left: 1px solid #999;
    height: 16px;
    width: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
}
.pi-navigation1 .share-box {
    position: absolute;
    top: 47%;
    right: 60px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.pi-navigation1 .share-box a {
    display: inline-block;
    margin: 0 5px;
    color: #aaa;
}
.pi-navigation1 .share-box a:hover {
    color: #e15d5d;
}
.navlist {
    position: relative;
    font-size: 0;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.navlist > li.menu-item-has-children.item-plus > a {
    position: relative;
}
.navlist > li.menu-item-has-children.item-plus > a:after,
.navlist > li > ul > li.menu-item-has-children.item-plus > a:after {
    content: '+';
    display: inline-block;
    margin-left: 4px;
}
.navlist > li > .sub-menu {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
}
.navlist li {
    position: relative;
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
}
.navlist li a {
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    color: #aaa;
    text-transform: uppercase;
    line-height: 40px;
}
.navlist li:first-child {
    margin-left: 0;
}
.navlist li .sub-menu {
    position: absolute;
    background-color: #fefefe;
    width: 190px;
    padding: 0;
    list-style: none;
    left: -15px;
    top: 97%;
    border: 1px solid #f3f3f3;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.navlist li .sub-menu li {
    display: block;
    margin: 0;
}
.navlist li .sub-menu li a {
    display: block;
    padding: 0 15px;
    border-bottom: 1px solid #f3f3f3;
    line-height: 40px;
    text-align: left;
}
.navlist > li > .sub-menu > li:last-child > a,
.navlist > li > .sub-menu > li > .sub-menu > li:last-child > a {
    border: 0;
}
.navlist li .sub-menu .sub-menu {
    left: 100%;
    top: -1px;
}
.navlist li.current-menu-parent > a,
.navlist li.current-menu-item > a, .navlist li:hover > a {
    color: #e15d5d;
}
.navlist li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.navlist li.megamenu {
    position: static;
}
.navlist li.megamenu:after {
    content: '';
    display: block;
    clear: both;
}
.navlist li.megamenu .sub-menu {
    width: 100%;
    padding: 15px;
    left: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
}
.navlist li.megamenu .sub-menu li {
    float: left;
    padding: 10px;
    background: none;
}
.navlist li.megamenu .sub-menu li a {
    border: 0;
    line-height: 2em;
    font-size: 12px;
}
.navlist li.megamenu .post {
    text-align: center;
    background-color: #fff;
    padding: 10px 10px 20px 10px;
}
.navlist li.megamenu .post .post-media img {
    width: 100%;
}
.navlist li.megamenu .post h2 {
    font-size: 12px;
    color: #484848;
    text-transform: uppercase;
    margin-top: 9px;
    margin-bottom: 5px;
}
.navlist li.megamenu .post h2 a {
    color: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.navlist li.megamenu .post h2 a:hover {
    color: #e15d5d;
}
.navlist li.megamenu .post .post-date {
    font-size: 12px;
}
.navlist li.megamenu.col-5 .sub-menu li {
    width: 20%;
}
.navlist li.megamenu.col-4 .sub-menu li {
    width: 25%;
}
.navlist li.megamenu.col-3 .sub-menu li {
    width: 33.3333333333%;
}
.navlist li.megamenu.col-2 .sub-menu li {
    width: 50%;
}
.navlist li.megamenu.col-1 .sub-menu li {
    width: 100%;
}
.navlist.off-canvas {
    position: fixed;
    width: 250px;
    top: 43px;
    left: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f7f9f9;
    -webkit-box-shadow: 0 100px 0 0 #f7f9f9;
    -moz-box-shadow: 0 100px 0 0 #f7f9f9;
    box-shadow: 0 100px 0 0 #f7f9f9;
    z-index: 99999;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.navlist.off-canvas li {
    position: static;
    display: block;
    margin: 0;
}
.navlist.off-canvas li a {
    display: block;
    border-bottom: 1px solid #eee;
    line-height: 42px;
    padding: 0 15px;
    text-align: left;
}
.navlist.off-canvas li .sub-menu {
    opacity: 1;
    visibility: visible;
    position: absolute;
    border: 0;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background-color: #f7f9f9;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
}
.navlist.off-canvas li .sub-menu li a {
    border-bottom: 1px solid #eee;
}
.navlist.off-canvas li .sub-menu.sub-menu-active {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    z-index: 999;
}
.navlist.off-canvas li.megamenu .sub-menu {
    padding: 0 15px;
    -webkit-box-shadow: 0;
    -moz-box-shadow: 0;
    box-shadow: 0;
}
.navlist.off-canvas li.megamenu .sub-menu li {
    width: 100% !important;
    float: none;
    padding: 10px 0;
}
.navlist.off-canvas li.megamenu .sub-menu li a {
    border: 0;
}
.navlist.off-canvas li.megamenu .sub-menu li.back-mb {
    width: auto !important;
    background-color: #f3f3f3;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 10px;
}
.navlist.off-canvas li.megamenu .sub-menu li.back-mb a {
    font-size: 11px;
}
.navlist.off-canvas li.megamenu .sub-menu li:last-child {
    padding-bottom: 30px;
}
.navlist.off-canvas li.back-mb {
    background-color: rgba(0, 0, 0, 0.02);
}
.navlist.off-canvas.off-canvas-active {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}
.navlist.off-canvas .submenu-toggle {
    position: absolute;
    right: 0;
    width: 42px;
    height: 42px;
    text-align: center;
    cursor: pointer;
    background-color: #eff1f1;
    background-color: rgba(0, 0, 0, 0.02);
    border-left: 1px solid #f2f2f2;
    color: #383838;
    -webkit-transform: translateY(-43px);
    -moz-transform: translateY(-43px);
    -ms-transform: translateY(-43px);
    -o-transform: translateY(-43px);
    transform: translateY(-43px);
}
.navlist.off-canvas .submenu-toggle .fa {
    font-size: 14px;
    line-height: 42px;
}
.open-menu {
    display: none;
    position: absolute;
    width: 50px;
    height: 14px;
    cursor: pointer;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
}
.open-menu .item {
    position: absolute;
    display: block;
    font-size: 0;
    width: 20px;
    height: 2px;
    background-color: #333;
    margin: auto;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.open-menu .item-1 {
    top: 0;
}
.open-menu .item-2 {
    top: 0;
    bottom: 0;
}
.open-menu .item-3 {
    bottom: 0;
}
.open-menu.toggle-active .item {
    background-color: #e15d5d;
}
.close-menu {
    position: absolute;
    width: 50px;
    height: 14px;
    cursor: pointer;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    display: none;
}


/* Featured Slider
----------------------------------------------- */
.owl-carousel .owl-controls {
    margin-top: 0;
}
.owl-carousel .owl-wrapper, .backlinks-container {
    display: none;
    position: relative;
}
.owl-carousel .owl-controls .owl-buttons {
    margin-top: 0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -30px;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    -webkit-transition: all 0.3s ease 0.3s;
    -moz-transition: all 0.3s ease 0.3s;
    -ms-transition: all 0.3s ease 0.3s;
    -o-transition: all 0.3s ease 0.3s;
    transition: all 0.3s ease 0.3s;
}
.owl-carousel .owl-controls .owl-buttons > div {
    display: inline-block;
    font-size: 14px;
    color: #aaa;
    overflow: hidden;
    margin: 3px;
}
.owl-carousel .owl-controls .owl-buttons > div .fa {
    position: relative;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 24px;
    border: 1px solid #aaa;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.owl-carousel .owl-controls .owl-buttons > div:hover .fa {
    border-color: #e15d5d;
    color: #e15d5d;
}
.owl-carousel .owl-controls .owl-pagination {
    margin-top: 30px;
}
.owl-carousel .owl-controls .owl-pagination .owl-page {
    position: relative;
}
.owl-carousel .owl-controls .owl-pagination .owl-page > span {
    opacity: 1;
    width: 9px;
    height: 9px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #1b1b1b;
    margin: 3px;
    background: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}
.owl-carousel .owl-controls .owl-pagination .owl-page.active > span {
    border-color: #e15d5d;
    background-color: #e15d5d;
}
.owl-carousel:hover .owl-controls .owl-buttons {
    bottom: -50px;
    opacity: 1;
    visibility: visible;
}
.featured .widget {
    margin: 0;
}
.post-slider.owl-carousel .owl-controls .owl-buttons,
.featured-slider.owl-carousel .owl-controls .owl-buttons {
    position: static;
    margin: 0;
    padding: 0;
    height: 0;
    opacity: 1;
    visibility: visible;
}
.post-slider.owl-carousel .owl-controls .owl-buttons > div,
.featured-slider.owl-carousel .owl-controls .owl-buttons > div {
    position: absolute;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9;
    opacity: .5;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.post-slider.owl-carousel .owl-controls .owl-buttons > div .fa,
.featured-slider.owl-carousel .owl-controls .owl-buttons > div .fa {
    border: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #FFF;
    font-size: 12px;
}
.post-slider.owl-carousel .owl-controls .owl-buttons > div.owl-prev,
.featured-slider.owl-carousel .owl-controls .owl-buttons > div.owl-prev {
    left: 5px;
}
.post-slider.owl-carousel .owl-controls .owl-buttons > div.owl-next,
.featured-slider.owl-carousel .owl-controls .owl-buttons > div.owl-next {
    right: 5px;
}
.post-slider.owl-carousel .owl-controls .owl-pagination,
.featured-slider.owl-carousel .owl-controls .owl-pagination {
    margin-top: 0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px;
    z-index: 9;
}
.post-slider.owl-carousel .owl-controls .owl-pagination .owl-page,
.featured-slider.owl-carousel .owl-controls .owl-pagination .owl-page {
    position: relative;
}
.post-slider.owl-carousel .owl-controls .owl-pagination .owl-page > span,
.featured-slider.owl-carousel .owl-controls .owl-pagination .owl-page > span {
    border: 0;
    background-color: #fff;
}
.post-slider.owl-carousel .owl-controls .owl-pagination .owl-page.active > span,
.featured-slider.owl-carousel .owl-controls .owl-pagination .owl-page.active > span {
    border-color: #e15d5d;
    background-color: #e15d5d;
}
.post-slider.owl-carousel:hover .owl-controls .owl-buttons > div,
.featured-slider.owl-carousel:hover .owl-controls .owl-buttons > div {
    opacity: 1;
}
#featured {
    margin: 0;
    display: block;
}
.owl-carousel {
    display: none;
    position: relative;
    width: 100%;
}
.owl-carousel .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 9;
}
.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
    backface-visibility: hidden;
}
.owl-carousel .owl-item {
    float: left;
}
ul.featured-slider {
    list-style: none outside none;
    padding: 0;
    margin: 0;
}
.featured-slider .post {
    position: relative;
    padding: 0;
    margin: 0;
}
.featured-slider .post .post-media {
    position: relative;
}
.featured-slider .post .post-media img {
    width: 100%;
    height: 320px;
    object-fit: cover;
}
.featured-slider .post .post-media:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.featured-slider .post .post-body {
    position: absolute;
    top: 50%;
    left: 0;
    opacity: 1;
    width: 100%;
    z-index: 9;
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%);
    transform: translateY(-30%);
    padding: 15px 60px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.featured-slider .post .post-body .cat {
    display: block;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.featured-slider .post .post-body .cat a {
    display: inline-block;
    color: #fff;
    padding: 4px 8px;
    border: 1px solid #fff;
    margin-right: 5px;
    margin-top: 5px;
}
.featured-slider .post .post-body .cat a:hover {
    border-color: #e15d5d;
    color: #e15d5d;
}
.featured-slider .post .post-body .post-title {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
}
.featured-slider .post .post-body .post-title h4 {
    margin-bottom: 0;
}
.featured-slider .post .post-body .post-title a {
    color: #fff;
}
.featured-slider .post .post-body .post-title a:hover {
    color: #e15d5d;
}
.featured-slider .post .post-body .post-meta .post-date {
    color: #eee;
    font-size: 11px;
    letter-spacing: 0.03em;
}
.featured-slider .post:hover .post-media:after {
    opacity: 1;
}
.featured-slider .post:hover .post-body {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
}
.owl-carousel .owl-controls {
    margin-top: 0;
    text-align: center;
}
.owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.featured-slider.owl-carousel .owl-controls .owl-buttons > div .fa {
    width: 38px;
    height: 38px;
    line-height: 38px;
}
.featured-slider.owl-carousel .owl-controls .owl-buttons > div .fa:hover {
    color: #e15d5d;
}
.featured-slider.owl-carousel .owl-controls .owl-buttons > div.owl-prev {
    left: 0;
}
.featured-slider.owl-carousel .owl-controls .owl-buttons > div.owl-next {
    right: 0;
}
.owl-controls .owl-page, .owl-controls .owl-buttons div {
    cursor: pointer;
}


/* Main content
----------------------------------------------- */
.blog-content {
    background-color: #f6f6f6;
    padding-bottom: 50px;
}
.blog-content .content {
    margin-bottom: 30px;
}
.blog-content .content .post-wrapper:after {
    content: '';
    display: table;
    clear: both;
}
.blog-content .post {
    position: relative;
    background-color: #fff;
    margin-bottom: 10px;
}
.blog-content .post:after {
    content: '';
    display: table;
    clear: both;
}
.blog-content .post .post-media {
    position: relative;
    overflow: hidden;
}
.blog-content .post .post-media .image-wrap {
    display: block;
}
.blog-content .post .post-media img {
    width: 100%;
}

.blog-content .post .post-meta .post-format {
    width: 60px;
    height: 60px;
    font-size: 18px;
    color: #4d4d4d;
    background-color: #fff;
    text-align: center;
    line-height: 60px;
    display: inline-block;
    margin-bottom: 10px;
}
.blog-content .post .post-meta .post-format a {
    display: block;
    color: inherit;
}
.blog-content .post .post-meta .post-date {
    background-color: rgba(255, 255, 255, 1);
    width: 60px;
    text-align: center;
    padding: 0 7px;
    font-size: 0;
    margin-bottom: 10px;
    border: 1px solid rgba(227, 227, 227, 1);
}
.blog-content .post .post-meta .post-date .year {
    display: block;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #707070;
    padding: 10px 0 6px 0;
    border-bottom: 2px solid #707070;
    line-height: 1.2em;
}
.blog-content .post .post-meta .post-date .month, .blog-content .post .post-meta .post-date .day {
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 10px;
    color: #707070;
    text-transform: uppercase;
    padding: 8px 0 10px 0;
    letter-spacing: 0;
    white-space: nowrap;
}
.blog-content .post .post-meta .post-date .day:before {
    content: '/';
}

.blog-content .post .post-meta .post-comment {
    background-color: rgba(255, 255, 255, 1);
    width: 60px;
    text-align: center;
    padding: 4px 8px;
    font-size: 0;
    margin-bottom: 10px;
    border: 1px solid rgba(227, 227, 227, 1);
}


.blog-content .post .post-meta .post-comment .fa {
    display: block;
    font-size: 18px;
    color: #707070;
    padding: 10px 0;
    border-bottom: 2px solid #707070;
}

.blog-content .post .post-meta .post-comment a {
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 10px;
    color: #707070;
    text-transform: uppercase;
    padding: 8px 0 6px;
}

.blog-content .post .post-meta .post-comment a:hover {
    color: #e15d5d;
}
.blog-content .post .post-cat {
    position: absolute;
    display: block;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    top: 5px;
    left: 5px;
    z-index: 999;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.blog-content .post .post-cat a {
    display: inline-block;
    color: #fff;
    padding: 4px 8px;
    background-color: #e15d5d;
    margin: 5px;
}
.blog-content .post .post-cat a:hover {
    background-color: #444;
}
.blog-content .post .post-body {
    position: relative;
    padding: 40px 30px 0 30px;
    margin-bottom: 40px;
}
.blog-content .post .post-body:after {
    content: '';
    display: table;
    clear: both;
}
.blog-content .post .post-body .post-author {
    margin-bottom: 35px;
    padding: 0 25px;
}
.blog-content .post .post-body .post-author .image-thumb {
    width: 32px;
    height: 32px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
.blog-content .post .post-body .post-author .image-thumb img {
    width: 100%;
}
.blog-content .post .post-body .post-author .name-author {
    display: inline-block;
    vertical-align: middle;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    color: #484848;
    text-transform: uppercase;
}
.blog-content .post .post-body .post-author .name-author cite {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.blog-content .post .post-body .post-author .name-author:before {
    content: '// ';
}
.blog-content .post .post-body .post-author a:hover * {
    color: #e15d5d;
}
.blog-content .post .post-body .post-title h1 {
    font-size: 18px;
    color: #484848;
    text-transform: uppercase;
    margin-top: 0;
}
.blog-content .post .post-body .post-title h2 {
    font-size: 14px;
    color: #484848;
    text-transform: uppercase;
    margin-top: 0;
    padding: 0 25px;
}
.blog-content .post .post-body .post-title h2 a {
    display: block;
    color: inherit;

}
.blog-content .post .post-body .post-title h2 a:hover {
    color: #e15d5d;
}
.blog-content .post .post-body .post-entry {
    margin-top: 20px;
    line-height: 1.8em;
    letter-spacing: 0.02em;
    padding: 0 25px;
}
.blog-content .post .post-body .post-link {
    display: inline-block;
    margin-top: 30px;
}
.blog-content .post .post-body .post-share {
    position: absolute;
    display: inline-block;
    min-height: 42px;
    right: 30px;
    bottom: 0;
    padding-right: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.blog-content .post .post-body .post-share .share-toggle {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    height: 42px;
    line-height: 40px;
    padding: 0;
    border: 0;
    color: #e15d5d;
}
.blog-content .post .post-body .post-share .share {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
    padding: 10px 0 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.blog-content .post .post-body .post-share .share a {
    display: inline-block;
    color: #535353;
    line-height: 36px;
    line-height: 5px;
    border: 1px solid #ddd;
    padding: 8px;
    width: 34px;
    text-align: center;
}
.blog-content .post .post-body .post-share .share a:hover {
    color: #e15d5d;
}
.blog-content .post .post-body .post-share .share.share-active {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
    opacity: 1;
    visibility: visible;
}
.blog-content .post:hover .post-meta .post-format {
    color: #e15d5d;
}
.blog-content .post:hover .post-cat {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.ismobile .blog-content .post .post-cat {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.blog-heading {
    position: relative;
    padding: 30px 0;
    background-color: #f3f5f5;
    border-bottom: 1px solid #eee;
    z-index: 9;
}
.blog-heading h1 {
    margin: 0;
    font-size: 30px;
    letter-spacing: 0.03em;
}
.blog-heading p {
    margin: 0;
    letter-spacing: 0.03em;
    color: #666;
}
.post-slider .owl-controls .owl-buttons {
    position: static;
    margin: 0;
    padding: 0;
    height: 0;
    opacity: 1;
    visibility: visible;
}
.post-slider .owl-controls .owl-buttons > div {
    position: absolute;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9;
    opacity: .8;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.post-slider .owl-controls .owl-buttons > div .fa {
    border: 0;
    background-color: #fff;
    color: #333;
}
.post-slider .owl-controls .owl-buttons > div.owl-prev {
    left: 10px;
}
.post-slider .owl-controls .owl-buttons > div.owl-next {
    right: 10px;
}
.post-slider .owl-controls .owl-pagination {
    margin-top: 0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px;
    z-index: 9;
}
.post-slider .owl-controls .owl-pagination .owl-page {
    position: relative;
}
.post-slider .owl-controls .owl-pagination .owl-page > span {
    border: 0;
    background-color: #fff;
}
.post-slider .owl-controls .owl-pagination .owl-page.active > span {
    border-color: #e15d5d;
    background-color: #e15d5d;
}
.post-slider:hover .owl-controls .owl-buttons > div {
    opacity: 1;
}

body.single-post .blog-content .post .post-body {
    margin-bottom: 30px;
}
body.single-post .blog-content .post .post-body .post-share {
    position: relative;
    float: right;
    width: 100%;
    text-align: right;
    right: 0;
    padding-top: 30px;
}
body.single-post .blog-content .post .post-body .post-share .share-toggle {
    top: 30px;
}
.post .wp-caption {
    display: inline-block;
}
.post .wp-caption,
.post .wp-caption img {
    width: auto !important;
}
.post .wp-caption img {
    position: relative;
    margin-bottom: 10px;
    top: 5px;
}
.post .wp-caption .wp-caption-text {
    font-size: 12px;
    font-style: italic;
    text-align: center;
    margin-bottom: 10px;
}
.alignleft {
    float: left;
    margin-right: 30px;
}
.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.alignright {
    float: right;
    margin-left: 30px;
}
.blog-pager {
    display: block;
    overflow: hidden;

    margin-top: 0;
    margin-bottom: 10px;
    padding: 20px 30px 10px;
}
.blog-pager a {
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #e15d5d;
    text-transform: uppercase;
}
.blog-pager a:hover {
    color: #535353;
}
.blog-standard .content {
    margin-top: 50px;
}
.blog-grid .content {
    margin-top: 50px;
}
.blog-grid .post-wrapper {
    margin-left: -15px;
    margin-right: -15px;
}
.blog-grid .post {

    margin-bottom: 30px;

    border-radius: 4px;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.125);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.125);
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.125);
}
.blog-grid .post .post-meta {
    top: 10px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0;
}
.blog-grid .post .post-body {
    padding: 15px 0px 0 0px;
    margin-bottom: 8px;
    margin-top: -58px;
    background-color: rgba(255, 255, 255, 0.40);
}
.blog-grid .post .post-body .post-share {
    right: 25px;
}

.status-msg-wrap {
    font-size: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto 30px;
    padding: 19px;
}
.status-msg-body {
    position: relative;
    text-align: left;
    padding: 0px;
}
.status-msg-body b {
    color: #fff;
    padding: 3px;
    margin: 0 5px 0 0;
    background-color: #E15D5D;
    border-radius: 2px;
}
.status-msg-border {
    display: none;
}

/* Sidebar
----------------------------------------------- */
.sidebar {
    margin-top: 50px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
}

#sidebar .widget {
    background: #fff;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #e4e4e4;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    padding: 25px;
}

#sidebar h2 span {
    border-bottom: 3px solid #e74b3c;
    padding-bottom: 5px;
}
.widget {
    margin-bottom: 50px;
}
.widget > h2 {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #707070;
    padding: 0 15px;
    line-height: 40px;
    margin-top: 0;
    text-align: center;
    margin-bottom: 13px;
}

.widget ul {
    list-style: none;
    padding: 0;
}
.widget select {
    width: 100%;
}
.Label .list-label-widget-content ul li {
    font-size: 14px;
    color: #848484;
    border-bottom: 1px solid #EDEDED;
    padding: 0;
}
.Label .list-label-widget-content ul li a {
    display: inline-block;
    color: inherit;
    padding-top: 15px;
    padding-bottom: 15px;
}
.Label .list-label-widget-content ul li span {
    float: right;
    padding-top: 15px;
    padding-bottom: 15px;
}
.Label .list-label-widget-content ul li span {
    padding-top: 10px;
    padding-bottom: 10px;
}
.Label .list-label-widget-content ul li > span:first-child {
    float: none;
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #E15D5D;
    border-top: 1px dashed #E15D5D;
    border-bottom: 1px dashed #E15D5D;
}
.Label .cloud-label-widget-content .label-size {
    font-size: 100%;
    float: left;
}
.Label .cloud-label-widget-content .label-size a {
    display: inline-block;
    margin: 2px;
    font-family: "Montserrat",sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    color: #888;
    background-color: #FFF;
    padding: 5px 8px;
    letter-spacing: 0.03em;
    border: 3px double #eee;
}
.Label .cloud-label-widget-content .label-size a:hover {
    border-color: #E15D5D;
    background-color: #E15D5D;
    color: #FFF;
}
.Label .cloud-label-widget-content .label-size .label-count,
.Label .cloud-label-widget-content .label-size > span {
    display: inline-block;
    font-family: "Montserrat",sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    color: #888;
    background-color: #FFF;
    padding: 5px 8px;
    letter-spacing: 0.03em;
    margin: 2px 2px 2px -5px;
}
.Label .cloud-label-widget-content .label-size > span {
    margin: 2px;
    border-color: #E15D5D;
    background-color: #E15D5D;
    color: #FFF;
}

/* Footer
----------------------------------------------- */
#footer {
    background-color: #fff;
    padding-top: 20px;
    -moz-box-shadow: 1px -2px 3px 0px rgba(0,0,0,0.05);
    -webkit-box-shadow: 1px -2px 3px 0px rgba(0,0,0,0.05);
    box-shadow: 1px -2px 3px 0px rgba(0,0,0,0.05);
    color: #aaa;
}

.footer h2 span {
    border-bottom: 3px solid #e74b3c;
    padding-bottom: 5px;
}
#footer .widget {
    padding-top: 20px;
    margin-bottom: 10px;
}
#footer .widget > h2 {
    position: relative;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #707070;
    padding: 0 15px;
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 13px;
    text-align: center;

}
#footer .widget > h2:after {
    display: none;
}
#footer .widget .widget-content ul li {
    font-size: 14px;
    border: 0;
    padding: 0;
    color: #707070;
}
#footer .widget .widget-content ul li a {
    padding-top: 10px;
    padding-bottom: 10px;
    color: inherit;
    display: inline-block;
}
#footer .widget .widget-content ul li a:hover {
    color: #bbb;
}
#footer .row {
    margin-left: -25px;
    margin-right: -25px;
}
#footer [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
}
.separator a {
    margin-left: 0px!important;
}
.totop {
    display: none;
}
.totop-inner {
    z-index: 299;
    position: fixed;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: #FFF;
    top: auto;
    left: auto;
    right: 30px;
    bottom: 50px;
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.totop-inner:hover {
    background-color: #E15D5D;
}
.copyright {
    margin-top: 40px;
    padding: 15px 0 12px;
    background-color: #444;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
}
.copyright p {
    margin: 0;
}

.footer-left{float:left;padding: 2px 0 0;}
.footer-right{float:right}

.FollowByEmail .widget-content .desc {
    margin-top: 20px;
}
.FollowByEmail .widget-content form {
    position: relative;
    margin-top: 15px;
}
.FollowByEmail .widget-content .form-item input {
    width: 100%;
    height: auto;
    padding-right: 77px;
    font-size: inherit;
    border-width: 0;
}
.FollowByEmail .widget-content .form-actions {
    position: absolute;
    top: 0;
    right: 0;
}
.FollowByEmail .widget-content .form-actions input {
    background-color: #111;
    border: 0;
    height: 42px;
    width: auto;
    line-height: 42px;
    font-size: 11px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    color: #555;
    padding: 0 15px;
    margin: 0;
    border-radius: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.FollowByEmail .widget-content .form-actions input:hover {
    color: #e15d5d;
}
.contact-form-email:focus, .contact-form-name:focus, .contact-form-email-message:focus {
    border-color: #111;
}
.contact-form-button {
    line-height: 18px;
}

.contact-form-name, .contact-form-email, .contact-form-email-message {
    max-width: 365px;
    width: 100%;
}
.contact-form-widget {
    height: 320;
    margin-left: 0;
    max-width: 365px;
    padding: 0;
    padding-top: 0;
    width: 100%;
}

.contact-form-widget input[type="search"],.contact-form-widget input[type="text"],.contact-form-widget input[type="url"], .contact-form-widget input[type="number"],.contact-form-widget input[type="password"],.contact-form-widget input[type="email"], .contact-form-widgetinput[type="file"] {
    background: none;
    border: 0;
    background-color: #111;
    height: 42px;
    line-height: 42px;
    padding: 0 15px;
    color: #888888;
    font-family: "Lato", sans-serif !important;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


.contact-form-email-message {
    background: #111;
    background-color: #111;
    border: 1px solid #111;
    border-top: 1px solid #111;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    font-family: Arial,sans-serif;
    font-size: 13px;
    margin: 0;
    margin-top: 5px;
    padding: 0;
    vertical-align: top;
    padding: 10px;
}


.contact-form-button-submit {
    border: 0;
    background-image: none;
    background-color: none;
    cursor: pointer;
    font: $(body.font);
    font-style: normal;
    font-weight: 400;
    display: block;
    padding: 20px 20px;
    margin-top: 12px;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    float: right;
    color: #b0b0b0;
    font-size: 12px;
    line-height: 1px;
    text-transform: uppercase;
    background-color: #111;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
}
.contact-form-email:hover, .contact-form-name:hover, .contact-form-email-message:hover {
    border: 1px solid #111;
    border-top: 1px solid #111;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}


/* Posts and Pages
----------------------------------------------- */

.item .post, .static_page .post {
    overflow: visible !important;
    margin-bottom: 10px !important;
}
.item .entry-content, .static_page .entry-content {
    position: relative;
    padding: 20px 30px 0;
    margin-bottom: 30px;
}
.item .entry-title, .static_page .entry-title {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 0px;
    padding: 20px 0 10px;
    margin: 0 30px;
    border-bottom: 2px solid #e3e3e3;
}
.item .entry-title i, .static_page .entry-title i {
    margin-right: 5px;
}
.item .entry-content ul, .static_page .entry-content ul {
    list-style-type: disc;
    padding: 0 30px;
    margin-top: 0;
    margin-bottom: 10px;
}
.item .entry-content ol, .static_page .entry-content ol {
    list-style-type: decimal;
    padding: 0 30px;
    margin-top: 0;
    margin-bottom: 10px;
}
.item .post-share, .static_page .post-share {
    position: relative;
    float: right;
    width: 100%;
    right: 0;
    padding: 30px;
}
.item .post-share .pi-btn, .static_page .post-share .pi-btn {
    display: none;
}
.item .post-share .share, .static_page .post-share .share {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
    opacity: 1;
    visibility: visible;
    float: right;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.item .post-share .share.share-active, .static_page .post-share .share.share-active {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
    opacity: 1;
    visibility: visible;
}
.item .post-share .share a, .static_page .post-share .share a {
    display: inline-block;
    color: #535353;
    line-height: 36px;
    line-height: 5px;
    border: 1px solid #ddd;
    padding: 8px;
    width: 34px;
    text-align: center;
}
.related-post {
    background-color: #fff;
    padding: 50px 30px;
    margin-bottom: 10px;
    background: #fff;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #e4e4e4;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.related-post h4 {
    margin-top: 0;
    font-size: 16px;
    color: #484848;
    text-transform: uppercase;
}
.col-md-4 {
    padding: 0 !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
}
.related-post .related-post-item {
    text-align: center;
    margin-top: 35px;
}
.related-post .related-post-item .post-media img {
    width: 100%;
}
.related-post .related-post-item h2 {
    font-size: 12px;
    color: #484848;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 5px;
}
.related-post .related-post-item h2 a {
    color: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
}
.related-post .related-post-item h2 a:hover {
    color: #e15d5d;
}
.signature {
    padding: 30px 20px 40px 20px;
}
.signature .inner {
    display: inline-block;
    text-align: center;
}
.signature img {
    width: 135px;
}
.signature .name {
    display: block;
    margin-top: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: #555;
}
.about-author {
    position: relative;
    margin-bottom: 10px;
    padding: 30px;
    background-color: #fff;
    background: #fff;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #e4e4e4;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.about-author .image-thumb {
    width: 120px;

}
.about-author .author-name h4 {
    font-size: 14px;
    margin-top: 0;
}
.about-author .author-info {
    margin-left: 150px;
}
.about-author .author-social {
    margin-left: -6px;
    margin-right: -6px;
}
.about-author .author-social a {
    display: inline-block;
    font-size: 14px;
    color: #161616;
    margin: 4px 6px;
}
.about-author .author-social a:hover {
    color: #e15d5d;
}
#comments {
    margin-top: 40px;
    background-color: #fff;
    padding: 0px 20px;
    display: inline-block;
    width: 100%;
    border-bottom-width: 0;
    background: #fff;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 3px 5px rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #e4e4e4;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#comments > h4 {
    color: #7B7B7B;
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
}
#comments > h4 i {
    float: right;
    line-height: 1.5em;
}
#comments #comment-post-message {
    padding-left: 20px;
    color: #7B7B7B;
}
#comments .user a {
    color: #555;
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: 1px;
}
#comments .datetime,
#comments .datetime a {
    color: #999;
    text-decoration: none;
    margin: 0 0px 0px;
    font-size: 14px;
    display: inline-block;
    float: right;
}
.comments .comments-content .comment-content {
    margin: 0 0 20px;
    color: #7B7B7B;
}
#comments .datetime a {
    margin:auto;
}
#comments .comment-actions a {
    background-color: #E15D5D;
    border: 1px solid #E15D5D;
    border-radius: 2px;
    color: #FFF;
    font-size: 11px;
    line-height: 1.2em;
    padding: 3px 9px 2px;
    text-transform: uppercase;
    text-decoration: none;
    margin-right: 10px;
}
#comments .comment-actions a:hover {
    color: #fff;
    background-color: #555;
    border: 1px solid #555;
}
#comments .comment {
    border-bottom: 1px solid #eee;
    padding: 0;
}
#comments .loadmore {
    margin-top: 0;
}
.comment-replies .inline-thread {
    background: #F7F7F7;
    border-width: 1px 1px 1px 3px;
    border-style: solid;
    border-color: #ddd #ddd #ddd #E15D5D;
    margin: 20px 0 20px !important;
}
#comment-editor {
    height: 235px;
}
.comments .comments-content .avatar-image-container {
    max-height: 76px;
    width: 76px;
}
#comments .comments-content .avatar-image-container img {
    max-width: 76px;
    border: 1px solid #eee;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
.comments .comment-block {
    margin-left: 98px;
}
span.icon.user.blog-author:after {
    background-color: #999;
    color: #eee;
    padding: 2px 5px;
    border-radius: 3px;
    font-weight: normal;
    font-size: 13px;
    content: "Admin";
    display: inline-block;
}
.thread-toggle {
    display: block !important;
}
.thread-toggle.thread-expanded {
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 0px 0 10px;
    margin-bottom: 10px;
}
.comments .thread-toggle .thread-arrow {
    margin: 0px 10px 1px 0;
}
#comments .thread-chrome .comment {
    border-bottom-color: #ddd !important;
}
/*---Flicker Image Gallery-----*/
.flickr_plugin {
    width: 100%;
}
.flickr_badge_image {
    float: left;
    height: 75px;
    margin: 8px 5px 0px 5px;
    width: 75px;
}
.flickr_badge_image a {
    display: block;
}
.flickr_badge_image a img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: opacity 100ms linear;
    -moz-transition: opacity 100ms linear;
    -ms-transition: opacity 100ms linear;
    -o-transition: opacity 100ms linear;
    transition: opacity 100ms linear;
}
.flickr_badge_image a img:hover {
    opacity: .5;
}


#blog-pager {
    margin: 20px 0;
}
.showpageArea {
    display: inline-block;
    padding: 0 10px;
    position: relative;
    z-index: 2;

}
.showpageArea span {
    margin-bottom: 10px;
}
.blog-feeds {
    display: none;
}
.divider {
    position: relative;
    z-index: 1;
    margin: -60px 0 98px;
    text-align: center;
    border-bottom: 3px solid rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
    height: 6px;
}
.showpageOf {
    display: none;
}
.showpagePoint {
    font-weight: bold;
    text-decoration: none; 
    display: inline-block;
    background: #fff;
    color: #919394;
    border: 1px solid #d1d7dc;
    line-height: 1;
    padding: 10px 14px;
    margin: 3px 4px;
    transition: all 0.3s ease;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.04);

}
.showpageNum a {
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    background: #fff;
    color: #919394;
    border: 1px solid #d1d7dc;
    line-height: 1;
    padding: 10px 14px;
    margin: 3px 4px;
    transition: all 0.3s ease;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.04);

}
.showpage a {
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    background: #fff;
    color: #919394;
    border: 1px solid #d1d7dc;
    line-height: 1;
    padding: 10px 14px;
    margin: 3px 4px;
    transition: all 0.3s ease;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.04);

}
.showpage a:hover, .showpageNum a:hover {
    border-color: #a4a4a4;;
}
.home-link, .blog-pager-older-link, .blog-pager-newer-link {
    padding: 5px;
    overflow: hidden;
    background: none repeat scroll 0% 0% #000;
    color: #fff;
    z-index: 5;
    position: relative;
}

.postfooter {
    padding: 20px;
    font-size: 18px;
    background: #F7F8F9;
    padding-top: 0px;
}
.traingle {
    content: '';
    position: relative;
    z-index: 7;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    width: 0;
    margin-top: -19px;
    border-style: solid;
    border-width: 0 0 20px 250px;
    float: right;
    margin-bottom: -1px;
    border-color: transparent transparent #F7F8F9 transparent;
}
.postfooter .read {
    position: relative;
    z-index: 9;
    float: right;
    font-size: 12px;
    padding: 10px 26px;
    padding-left: 32px;
    color: #F2F2F2;
    margin-top: 5px;
    background: #555657;
    /* background: #DB3D3E; */
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    letter-spacing: 1px;
    font-family: Arial, sans-serif;
    margin-right: -29px;
    border-right: 8px solid rgba(0,0,0,.15);
    border-radius: 0 3px 0 0;
    -webkit-transform: perspective(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -moz-transform: perspective(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -o-transform: perspective(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -ms-transform: perspective(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transform: perspective(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}

.postfooter .read:hover {
    margin-right: -27px;
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(-32deg) rotateZ(0deg);
    -moz-transform: perspective(120px) rotateX(0deg) rotateY(-32deg) rotateZ(0deg);
    -o-transform: perspective(120px) rotateX(0deg) rotateY(-32deg) rotateZ(0deg);
    -ms-transform: perspective(120px) rotateX(0deg) rotateY(-32deg) rotateZ(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(-32deg) rotateZ(0deg);
}
.postfooter .read:before {
    content: '';
    position: absolute;
    margin-left: -33px;
    margin-top: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 44px 0 0 15px;
    border-color: transparent transparent transparent #F7F8F9;
}
.postfooter .read:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: -7px;
    margin-bottom: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 8px 0 0;
    border-color: #AFAFAF transparent transparent transparent;
}
.postfooter .read:hover {
    opacity: .6;
}

.socialpost .icons {
    display: inline-block;
    padding-top: 4px;
}

.socialpost a .texts {
    width: 62px;
    display: inline-block;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    -ms-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    position: absolute;
    text-align: center;
    margin-left: -47px;
    margin-top: -35px;
    font-size: 12px;
    background: #E1382D;
    border-radius: 2px;
    color: #FFFFFF;
    opacity: 0;
}

.socialpost a:hover .texts {
    z-index: 999;
    opacity: 1;
}
.linker {
    background: #FFFFFF;
    color: #aaa;
    padding: 8px 20px;
    padding-right: 10px;
    margin-top: 0;
    border-radius: 0 0 4px 4px;
    position: relative;
}
.linker:before {
    content: '';
    position: absolute;
    z-index: 99;
    border-style: solid;
    border-width: 0 0 10px 15px;
    margin-left: -20px;
    margin-top: -28px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 0 240px;
    border-color: transparent transparent transparent #FFFFFF;
}

.linker a {
    color: #FFFFFF;
}

.linker .buttonlightbox {
    border: 1px solid #E7E7E7;
    line-height: 14px;
    margin-right: 10px;
    font-family: Verdana, Arial, sans-serif;
    text-transform: capitalize;
    border-radius: 70px;
    float: right;
    top: 8px;
    right: 72px;
    padding: 2px;
    width: 28px;
    height: 28px;
    padding: 6px;
    background: #FEFEFE;
    color: #656565;
    text-align: center;
}
.linker .buttonlightbox:hover {
    background: #F2F2F2;
    color: #989898;
}

.linker .forwards {
    float: right;
    padding: 0 5px;
    padding-left: 8px;
    background: #E1382D;
    color: #FFFFFF;
    border-radius: 100%;
    line-height: 28px;
    font-family: Verdana, Arial, sans-serif;
    font-size: 13px;
    width: 28px;
}
.linker .forwards a:hover {
    opacity: .7;
    color: #FFFFFF;
}
.linker .mcate {
    color: #BFBFBF;
    border: 1px solid #E7E7E7;
    line-height: 16px;
    margin-right: 10px;
    font-family: Verdana, Arial, sans-serif;
    text-transform: capitalize;
    border-radius: 70px;
    float: right;
    top: 8px;
    right: 36px;
    padding: 2px;
    width: 28px;
    height: 28px;
    padding: 5px;
    background: #FEFEFE;
}
.linker .mcate img {
    width: 24px;
    height: 24px;
    opacity: .55;
}
.linker .mcate a {
    color: #DFDFDF;
}
.linker .mcate .catepopup {
    position: absolute;
    z-index: 99;
    background: #FFFFFF;
    color: #C5C5C5;
    border: 1px solid #EAEAEA;
    padding: 4px 15px;
    border-radius: 3px;
    top: 9px;
    opacity: 0;
    width: 0px;
    right: 34px;
    overflow: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}
.linker .mcate:hover .catepopup {
    opacity: 1;
    width: auto;
    right: 78px;
}
.edit {
}
.edit .icon-comment-alt2 {
    color: #FFFFFF;
}
.colorlinks {
    color: #E1382D;
}


.socialpost a i {
    color: #a0a0a0 !important;
}

.socialpost i {



    margin-right: 12px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    opacity: .8;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;

}



.postfooter .read:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: -7px;
    margin-bottom: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 8px 0 0;
    border-color: #AFAFAF transparent transparent transparent;
}




.socialpost {

    line-height: 28px;
    color: #000000;
    float: left;
    margin-top: 4px;
}

.linker i.fa.fa-comment {
    margin-right: 5px;
    color: #aaa;
}
/* Responsive
----------------------------------------------- */
@media only screen and (min-width:992px) and (max-width:1199px) {
    .container {
        width: 970px;
    }
    .blog-content .col-md-9 {
        width: 69.8%;
    }
    .blog-content .col-md-3 {
        width: 30.2%;
    }
}
@media only screen and (max-width:991px) {
    .blog-content .col-md-9, .blog-content .col-md-3, #footer [class*="col-"] {
        width: 100%;
    }
    .post-inner {
        margin-left: 70px !important;
    }
}
@media only screen and (min-width:768px) and (max-width:991px) {
    .container {
        width: 750px;
    }
    .col-md-4 {
        width: 50%;
    }
}
@media only screen and (max-width:767px) {
    .container {
        width: 100%;
    }
    .col-md-4 {
        width: 50%;
    }
    .post-inner {
        margin-left: 0 !important;
    }
    .item .blog-content .post .post-meta,
    .static_page .blog-content .post .post-meta {
        position: relative;
        left: 0px !important;
        transform: none !important;
        display: inline-block;
        width: 100%;
        text-align: center;
        background-color: #F7F9F9;
        padding-bottom: 20px;
    }
    .item .post-meta .post-date,
    .static_page .post-meta .post-date,
    .item .post-meta .post-comment,
    .static_page .post-meta .post-comment
    {
        display: inline-block;
        margin: 0 auto;
        vertical-align: top;
    }
    .item .post-meta .post-comment .fa,
    .static_page .post-meta .post-comment .fa {
        padding: 8px 0 !important;
    }
    .item .post-meta,
    .static_page .post-meta {

    }
    #footer [class*="col-"] {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}
@media only screen and (max-width:660px) {
    .col-md-6 {
        width: 100%;
    }
    .col-md-4 {
        width: 100%;
    }
    div#topnav {
        display: none;
    }
}
@media only screen and (max-width:491px) {
    .col-md-4 {
        width: 100%;
    }
    div#topnav {
        display: none;
    }
}

.share a {
    display: inline-block;
    margin: -7px 1px;
    color: #535353;
}

</style>
<style>
body {font-size: 14px;}

.sidebar {
    display: none;
}

.col-md-9 {
    width: 100%;
}


.post-media :hover .mask {
    right: -600px;
}

.blog-content .post .post-media .image-wrap {
    display: block;
    position: relative;
}

.image-wrap .mask {
    width: 600px;
    height: 303px;
    background: rgba(255, 255, 255, .1);
    display: inline-block;
    position: absolute;
    right: 350px;
    top: 0;
    -moz-transform: skew(-45deg);
    -webkit-transform: skew(-45deg);
    -webkit-transition: all .6s ease-in-out;
    -moz-transition: all .6s ease-in-out;
    -ms-transition: all .6s ease-in-out;
    -o-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
}


.blog-content .post .post-meta .post-comment {
    background-color: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.blog-content .post .post-meta .post-comment .fa {
    color: #fff;
    border-bottom: 2px solid #fff;
}

.blog-content .post .post-meta .post-comment a {
    color: #fff;
}

.blog-content .post .post-meta .post-date {
    background-color: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.blog-content .post .post-meta .post-date .year {
    color: #fff;
    border-bottom: 2px solid #fff;
}

.blog-content .post .post-meta .post-date .month, .blog-content .post .post-meta .post-date .day {
    color: #fff;
}


.blog-grid .post:hover .post-meta {
    right: 10px!important;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.blog-content .post .post-meta {
    position: absolute;
    top: 0;
    right: -10px!important;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    z-index: 99;
}

.blog-content .post .post-meta {
    position: absolute;
    top: 10px;
    right: -10px!important;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    z-index: 99;
}

.blog-grid .post:hover .post-meta {
    right: 10px!important;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}



@media only screen and (min-width:992px) and (max-width:1199px) {
    .container {
        width: 970px;
    }
    .blog-content .col-md-9 {
        width: 100%;
    }
}

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;
}

.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
}

.fancybox-skin {
    position: relative;
    background: #f9f9f9;
    color: #444;
    text-shadow: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.fancybox-opened {
    z-index: 8030;
}

.fancybox-opened .fancybox-skin {
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
    position: relative;
}

.fancybox-inner {
    overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch;
}

.fancybox-error {
    color: #444;
    font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
    margin: 0;
    padding: 15px;
    white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url(http://3.bp.blogspot.com/-th8ud8cTu-A/VZEJtPqU86I/AAAAAAAAF98/OCCrKEzh73E/s1600/fancybox_sprite.png);
}

#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
    background-position: 0 -108px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 8060;
}

#fancybox-loading div {
    width: 44px;
    height: 44px;
    background: url(ttp://3.bp.blogspot.com/-okEcm3h39X4/VZEKPmAR6QI/AAAAAAAAF-M/bUxvlD5I7gI/s1600/fancybox_loading.gif) center center no-repeat;
}

.fancybox-close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 8040;
}

.fancybox-nav {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    background: transparent url(blank.gif); /* helps IE */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    z-index: 8040;
}

.fancybox-prev {
    left: 0;
}

.fancybox-next {
    right: 0;
}

.fancybox-nav span {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 34px;
    margin-top: -18px;
    cursor: pointer;
    z-index: 8040;
    visibility: hidden;
}

.fancybox-prev span {
    left: 10px;
    background-position: 0 -36px;
}

.fancybox-next span {
    right: 10px;
    background-position: 0 -72px;
}

.fancybox-nav:hover span {
    visibility: visible;
}

.fancybox-tmp {
    position: absolute;
    top: -99999px;
    left: -99999px;
    max-width: 99999px;
    max-height: 99999px;
    overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: visible !important;
    width: auto;
}

.fancybox-lock body {
    overflow: hidden !important;
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
    z-index: 8010;
    background: url(http://1.bp.blogspot.com/-i1eHHBrnFNs/VZEKNyEdhpI/AAAAAAAAF-E/OpFoVgeuG44/s1600/fancybox_overlay.png);
}

.fancybox-overlay-fixed {
    position: fixed;
    bottom: 0;
    right: 0;
}

.fancybox-lock .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
    visibility: hidden;
    font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
    position: relative;
    text-shadow: none;
    z-index: 8050;
}

.fancybox-opened .fancybox-title {
    visibility: visible;
}

.fancybox-title-float-wrap {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-bottom: -35px;
    z-index: 8050;
    text-align: center;
}

.fancybox-title-float-wrap .child {
    display: inline-block;
    margin-right: -100%;
    padding: 2px 20px;
    background: transparent; /* Fallback for web browsers that doesn't support RGBa */
    background: rgba(0, 0, 0, 0.8);
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    text-shadow: 0 1px 2px #222;
    color: #FFF;
    font-weight: bold;
    line-height: 24px;
    white-space: nowrap;
}

.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}

.fancybox-title-inside-wrap {
    padding-top: 10px;
}

.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, .8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5){

    #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
        background-image: url(fancybox_sprite@2x.png);
        background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
    }

    #fancybox-loading div {
        background-image: url(fancybox_loading@2x.gif);
        background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
    }
}

a.fancybox i{
    color: #aaa;
}
